@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter", sans-serif;
}
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom, #0070ff, #00d2ff, #0070ff);
  border-radius: 10px;
  border: 2px solid #ffff;
}

body {
  background-color: #f7fafc;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  max-width: max-content;
  height: auto;
  background-color: #008cff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #008cff;
  transition: opacity 0.3s ease-in-out;
}

.tooltip:hover .tooltiptext::after {
  opacity: 1;
}

.h-screen-20 {
  height: calc(100vh - 5rem);
}

.main-section {
  @apply mx-2 md:col-span-2 rounded-md mt-1;
}

.leftbar {
  @apply w-2/3 md:block md:w-full md:col-span-1 bg-white fixed md:sticky top-20 pt-5 h-[85vh] rounded-md border;
}

.rightbar {
  @apply hidden md:block md:col-span-1 bg-white sticky top-20 h-[85vh] p-5 rounded-md border;
}
.loader {
  width: 20px;
  height: 20px;
  border: 3px dotted #0ac9ff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #197fc7;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.admin-loader {
  width: 84px;
  height: 84px;
  position: relative;
  overflow: hidden;
}
.admin-loader:before,
.admin-loader:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #0ac9ff;
  transform: translate(-50%, 100%) scale(0);
  animation: push 2s infinite ease-in;
}
.admin-loader:after {
  animation-delay: 1s;
}
@keyframes push {
  0% {
    transform: translate(-50%, 100%) scale(1);
  }
  15%,
  25% {
    transform: translate(-50%, 50%) scale(1);
  }
  50%,
  75% {
    transform: translate(-50%, -30%) scale(0.5);
  }
  80%,
  100% {
    transform: translate(-50%, -50%) scale(0);
  }
}
